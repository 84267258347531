@import url('https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

/* Load Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base Styles */
@layer base {
    .text-scale-xl { @apply text-lg   sm:text-xl   print:text-lg;   }
    .text-scale-lg { @apply text-base sm:text-lg   print:text-base; }
    .text-scale-md { @apply text-sm   sm:text-base print:text-sm;   }
    .text-scale-sm { @apply text-xs   sm:text-sm   print:text-xs;   }
    .text-scale-xs { @apply text-2xs  sm:text-xs   print:text-2xs;  }

    html, body, #root { @apply h-full w-full; }
    body {
        @apply relative  leading-none  font-serif;
        @apply text-scale-md  print:text-xs;
    }
    
    h1 { @apply font-medium  text-4xl  sm:text-5xl  print:text-4xl; }
    h2 { @apply font-bold    text-2xl  sm:text-3xl  print:text-2xl; }
    h3 { @apply font-medium  text-base sm:text-lg   print:text-base; }

    .btn { @apply font-sans; }

    .btn-inactive { @apply opacity-80 pointer-events-none cursor-default; }

    /* Fix focus styling on ghost buttons (Modal X button) */
    .btn.btn-ghost:focus-visible { @apply outline-none bg-gray-500/40; }

    /* Float buttons for react-carousel */
    .carousel.carousel-slider { @apply pb-14 sm:pb-40; }
    .carousel .slide img.w-max-h { @apply w-auto; }
    
    /* Lengthen tooltips for Modal buttons */
    .tooltip.tooltip-bottom { --tooltip-tail: 6px; }

    /* Fix Stat colors */
    .stats.text-primary-content .stat-title,
    .stats.text-primary-content .stat-desc   { color: oklch(var(--pc) / 0.6); }
    .stats.text-secondary-content .stat-title,
    .stats.text-secondary-content .stat-desc { color: oklch(var(--sc) / 0.6); }
    .stats.text-accent-content .stat-title,
    .stats.text-accent-content .stat-desc    { color: oklch(var(--ac) / 0.6); }

    /* Colorize imported SVGs */
    .fill-primary-content path   { fill: oklch(var(--pc)); }
    .fill-secondary-content path { fill: oklch(var(--sc)); }
    .fill-accent-content path    { fill: oklch(var(--ac)); }
}
